@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');
@import url('//fonts.googleapis.com/css?family=Source+Sans+Pro:600,400,700,300');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.App {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  height: calc(100vh - 40px);
}

div.column {
    background-color: #FAFAFA;
    margin-left: 20px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

div.column > p {
  font-weight: bold;
}

div.eventblock {
  margin: 0px 10px 10px 10px;
  border: 1px black solid;
}

div.fresh {
  background-color: #ccc;
}

div.warning {
  background-color: yellow;
}

div.urgent {
  background-color: #f00;
}

.bg-blue {
    background-color: #063B74;
}

.t-white {
    color: #fff;
}

#mast {
    width: 100%;
    height: auto;
}

    #mast img {
        width: 100%;
        height: auto;
    }

.card p {
    text-align: center;
}

h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    background-color: #063B74;
    font-size: 2rem;
    color: #041E3A;
}
